import React from "react";
import moment, { Moment } from "moment";
import {
  convertRelativeRangeToNonEmptyRange,
  RelativeRangeValue,
} from "../../COREDesignSystem/Form/CORERangePicker";
import {
  NonEmptyRange,
  RangeString,
  rangeToMomentDate,
  strToRange,
} from "./ranges";
import { Duration } from "moment/moment";

export type RangeFilterFormat = "YYYY-MM-DDTHH:mm:ssZ" | "YYYY-MM-DD";
export const dateFormatWithTimeZone: RangeFilterFormat = "YYYY-MM-DDTHH:mm:ssZ";
export const financeMarketTimeZone = "Australia/Sydney";
export const AEMOTimezone = "Australia/Brisbane";
export const datePickerFormat = "DD-MMM-YYYY";
export const dateAndTime = "DD-MMM-YYYY HH:mm";

export const momentToDateCSV = (moment: Moment): string => moment.toISOString();

type RangeCSVColumns = { fromDate: string; toDate: string };
export const dateRangeStrToCSVColumns = (
  dateRange: RangeString
): RangeCSVColumns => {
  const range = strToRange(dateRange);
  if (!range)
    return {
      fromDate: "-",
      toDate: "-",
    };
  const { to, from } = range;
  const { from: fromMoment, to: toMoment } = rangeToMomentDate(range);
  if (fromMoment === undefined || toMoment === undefined) {
    return {
      fromDate: "-",
      toDate: "-",
    };
  }
  if (!to.isValid() && from.isValid()) {
    return {
      fromDate: momentToDateCSV(fromMoment),
      toDate: "Ongoing",
    };
  }
  return {
    fromDate: momentToDateCSV(fromMoment),
    toDate: momentToDateCSV(toMoment),
  };
};

const defaultLocale = "en-US";

export const DateRenderer: React.FC<{
  dateString: string;
}> = ({ dateString }) => {
  return <>{moment(dateString).format(datePickerFormat)}</>;
};

export const setLocale = (locale: string) => {
  return localStorage.setItem("locale", locale);
};

const getLocale = () => {
  const locale = localStorage.getItem("locale");
  return locale !== null ? locale : defaultLocale;
};

export const formatDate = (
  date: Date,
  options: Exclude<Intl.DateTimeFormatOptions, "timeStyle"> & {
    timeStyle?: never;
  }
) => {
  const dateOptions = { ...options, timeStyle: undefined };
  return formatDateTime(date, dateOptions);
};

export const formatTime = (
  date: Date,
  options: Exclude<Intl.DateTimeFormatOptions, "dateStyle"> & {
    dateStyle?: never;
  }
) => {
  const timeOptions = { ...options, dateStyle: undefined };
  return formatDateTime(date, timeOptions);
};

export const formatDateTime = (
  date: Date,
  options: Intl.DateTimeFormatOptions
) => {
  const locale = getLocale();
  const props = {
    timeZone: financeMarketTimeZone,
    ...options,
  };
  return new Intl.DateTimeFormat(locale, props).format(date);
};

export const convertMomentToMarketTimezone = (momentTime: Moment) => {
  return moment(momentTime).clone().tz(financeMarketTimeZone);
};

export const formatRelativeRangeFilterForServer = (
  relativeRange: RelativeRangeValue,
  key: string,
  dateFormat: RangeFilterFormat
) => {
  return formatNonEmptyRangeToRangeFilter(
    convertRelativeRangeToNonEmptyRange(relativeRange),
    key,
    dateFormat
  );
};

export const formatNonEmptyRangeToRangeFilter = (
  range: NonEmptyRange,
  key: string,
  dateFormat: RangeFilterFormat
) =>
  `(${key}.gte.${convertMomentToMarketTimezone(
    range?.from.startOf("date")
  ).format(dateFormat)}, ${key}.lt.${convertMomentToMarketTimezone(
    range?.to.endOf("date")
  ).format(dateFormat)})`;

export const formatToLocalDateTimeFilter = (
  range: NonEmptyRange,
  key: string,
  dateFormat: RangeFilterFormat
) =>
  `(${key}.gte.${moment(range?.from.startOf("date"))
    .clone()
    .format(dateFormat)}, ${key}.lt.${moment(range?.to.endOf("date"))
    .clone()
    .format(dateFormat)})`;

const displayErrorMessageWhenIncorrectDate = (
  startDate: Moment,
  units: Intl.RelativeTimeFormatUnit[] = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second",
  ]
) => {
  const errorMessage =
    "No unit provided is small enough to display. Units provided: " +
    JSON.stringify(units) +
    " start date: " +
    startDate.format();

  console.error(errorMessage);
  return "Incorrect date";
};

export const displayRelativeDate = (
  startDate: Moment,
  units: Intl.RelativeTimeFormatUnit[] = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second",
  ],
  options: Intl.RelativeTimeFormatOptions = { style: "long" }
): string => {
  if (moment(startDate).isAfter(moment())) {
    return displayErrorMessageWhenIncorrectDate(startDate, units);
  }

  const rtf = new Intl.RelativeTimeFormat("en", options);
  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    const numberOfUnits = moment().diff(startDate, unit);

    if (numberOfUnits >= 1) {
      return rtf.format(-numberOfUnits, unit);
    }
    if (i === units.length - 1 && numberOfUnits === 0) {
      return rtf.format(-0, unit);
    }
  }

  return displayErrorMessageWhenIncorrectDate(startDate, units);
};

export const displayTimeUntilExpiry = (
  endOfDate: Moment,
  units: Intl.RelativeTimeFormatUnit[] = [
    "year",
    "month",
    "week",
    "day",
    "hour",
    "minute",
    "second",
  ]
): string => {
  const endDate = moment(endOfDate);
  let result = "";

  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    const unitEndDate = endDate.endOf(unit);
    const diff = unitEndDate.diff(moment(), unit);

    if (diff >= 1) {
      result = unitEndDate.fromNow();
      break;
    }
  }

  if (!result) {
    result = endDate.fromNow();
  }

  return result;
};

export const durationRange: Record<string, Duration> = {
  W: moment.duration(1, "weeks"),
  M: moment.duration(1, "months"),
  Q: moment.duration(3, "months"),
  H: moment.duration(6, "months"),
  Y: moment.duration(1, "years"),
};

export const isTodayDate = (date: Moment) => {
  const today = moment().startOf("day");
  return date.isSameOrAfter(today, "day");
};

export const isYesterdayDate = (date: Moment) => {
  const today = moment().startOf("day");
  const yesterday = moment().startOf("day").subtract(1, "day");
  return date.isSameOrAfter(yesterday, "day") && date.isBefore(today, "day");
};
