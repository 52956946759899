import { Task, TaskReferencePage } from "../../openapi-typescript/common/task";
import { TaskAssignment } from "../../openapi-typescript/common/task_assignment";
import { buildQuerySelect, keysToSnake } from "../global";
import { mutation } from "../state/appQueryClient";
import {
  APIResponse,
  getTotalRecords,
  InvalidateQueryFirstParam,
  TableOptions,
  useUpdatables,
} from "./useUpdatable";
import { taskInitPagination } from "../../modules/toDoList/TodoHelper";

export type TaskStatus = "To Do" | "Complete";

type TaskParams = {
  id?: Task["id"] | null;
  status?: TaskStatus;
  skip?: boolean;
  order?: string;
  invalidateQueries?: InvalidateQueryFirstParam;
} & TaskReferencePage;

type UseTotalTodoResult = {
  loading: boolean;
  sync: boolean;
  error: boolean;
  totalTodo: string | undefined;
};

type CreateTaskAssignment = Pick<TaskAssignment, "task" | "assignedUser">;

export const useTasks = (args?: TaskParams, tableOption?: TableOptions) => {
  const rest = useUpdatables<Task>(
    {
      get: [
        "getTask",
        {
          select: buildQuerySelect({
            id: "id",
            title: "title",
            description: "description",
            taskStatus: "task_status",
            createdUser: "users!created_user(name)",
            createdAt: "created_at",
            company: "company",
            dueDate: "due_date",
            portfolio: "portfolio",
            initiative: "initiative",
            emissionOffset: "emission_offset",
            certificateProject: "certificate_project",
            taskAssignments:
              "task_assignments(assignedUser:users!assigned_user(id,name))",
          }),
          ...keysToSnake({
            ...(args?.id && { id: `eq.${args.id}` }),
            ...(args?.portfolio && { portfolio: `eq.${args.portfolio}` }),
            ...(args?.initiative && { initiative: `eq.${args.initiative}` }),
            ...(args?.emissionOffset && {
              emissionOffset: `eq.${args.emissionOffset}`,
            }),
            ...(args?.certificateProject && {
              certificateProject: `eq.${args.certificateProject}`,
            }),
            ...(args?.status && { taskStatus: `eq.${args.status}` }),
            ...(args?.order && { order: args.order }),
          }),
        },
        args?.skip,
      ],
      add: (newItem) => ({
        queryKey: [
          "addTask",
          {
            action: "addTask",
            body: JSON.stringify(newItem),
            enabled: true,
          },
        ],
      }),
      update: (newItem, oldItem) => ({
        queryKey: [
          "editTask",
          {
            params: { id: `eq.${oldItem.id}` },
            action: "editTask",
            body: JSON.stringify(newItem),
            enabled: true,
          },
        ],
      }),
      transformToRichTypes: (record) => record,
      invalidateQueries: args?.invalidateQueries ?? ["getTask"],
    },
    tableOption
  );

  const assignTaskToUser = async (newItems: CreateTaskAssignment[]) => {
    return await mutation<APIResponse<TaskAssignment[]>>({
      queryKey: [
        "addTaskAssignment",
        {
          action: "addTaskAssignment",
          body: newItems,
          enabled: true,
        },
      ],
    });
  };

  const deleteAllAssigned = async (taskId: Task["id"]) => {
    return await mutation<APIResponse<null>>({
      queryKey: [
        "deleteTaskAssignment",
        {
          action: "deleteTaskAssignment",
          params: {
            task: `eq.${taskId}`,
          },
          enabled: true,
        },
      ],
    });
  };

  return {
    ...rest,
    assignTaskToUser,
    deleteAllAssigned,
  };
};

export const useTask = (args?: TaskParams) => {
  const { updatable, ...rest } = useTasks({
    ...args,
    skip: !args?.id || args?.skip,
  });

  const updatableTask = updatable.find(
    (i) => args?.id && i.data?.id === args.id
  );

  return {
    updatable: updatableTask,
    ...rest,
  };
};

export const useTotalTodo = () => {
  const { sync, loading, error, headers } = useTasks(
    { status: "To Do" },
    { pagination: taskInitPagination }
  );

  const createResponse = (
    overrides: Partial<UseTotalTodoResult>
  ): UseTotalTodoResult => ({
    loading: false,
    sync: false,
    error: false,
    totalTodo: undefined,
    ...overrides,
  });

  if (error) return createResponse({ error: true });

  if (loading && !sync) return createResponse({ loading: true });

  const total = getTotalRecords(headers);
  const totalTodo = total < 99 ? `${total}` : "99+";

  return createResponse({ loading, sync, totalTodo });
};
