import moment from "moment";
import { Task } from "../../openapi-typescript/common/task";
import {
  grey140,
  orange100,
  red100,
} from "../../COREDesignSystem/Content/COREColour";

export const taskInitPagination = {
  total: Number.MAX_SAFE_INTEGER,
  hideOnSinglePage: false,
  current: 1,
  pageSize: 20,
};

export const redirectCreatePath = (task?: Task) => {
  if (task?.certificateProject) {
    return `/markets-admin/certificate-projects/projects/${task.certificateProject}/project-details`;
  }
  if (task?.emissionOffset) {
    return `/emissions-manager/offsets/${task.emissionOffset}/view`;
  }
  if (task?.initiative) {
    return `/emissions-manager/initiatives/${task.initiative}/view`;
  }
  if (task?.portfolio) {
    return `/emissions-manager/portfolios/${task.portfolio}/edit`;
  }
  return "";
};

export const checkTodoDateStatus = (dueDate?: string) => {
  const now = moment();
  const due = moment(dueDate, "YYYY-MM-DD");

  const hoursDifference = due.diff(now, "hours");
  if (hoursDifference < 0) {
    return red100;
  } else if (hoursDifference <= 48) {
    return orange100;
  } else {
    return grey140;
  }
};
