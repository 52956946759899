import React from "react";
import { useUserLoggedInStatus, useUserRole } from "../../../shared/state/user";

import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { COREError } from "../../../COREDesignSystem/Content/COREError";
import { CORELoading } from "../../../COREDesignSystem/Feedback/CORELoading";
import { PageLayout } from "./PageLayout";
import { Empty } from "antd";
import {
  CURRENT_VERSION,
  fetchVersionFromIndexHtml,
  isVersionValid,
  useCheckFEVersionHasChanged,
} from "../../../shared/useCheckFEVersionHasChanged";
import { usePageChange } from "../../../shared/usePageChange";
import { useUserAccessibleSections } from "../../../shared/customHoooks/useUserAccessibleSections";
import { UserAccessibleSection } from "../../../openapi-typescript/common/user_accessible_section";
import { useUserDashboard } from "../../../shared/customHoooks/useUserDashboard";

export type DashboardLayoutRouteProps = Pick<
  RouteProps,
  "exact" | "component"
> & {
  path: Exclude<RouteProps["path"], undefined | readonly string[]>;
};

const noPermissionMessage = "No permission to view this page.";
const redirectToLogin = (pathname: string) => {
  const to =
    Boolean(pathname) && pathname !== "/"
      ? `/login?next=${pathname}`
      : "/login";
  return <Redirect to={to} />;
};

export const checkUserAccess = (
  userAccessibleSections: UserAccessibleSection[],
  path: DashboardLayoutRouteProps["path"]
) => {
  const locationPathName = window.location.pathname;
  const ignorePages = [
    "/website-terms-of-use",
    "/privacy-policy",
    "/terms-and-conditions",
    "/my-details",
    "/help",
    "/todo",
  ];

  const hasPermission = (sections: UserAccessibleSection[]): boolean => {
    return sections.some((section) => {
      const { callToActionUrl, children } = section;
      const isDirectMatch =
        path === callToActionUrl || locationPathName === callToActionUrl;
      const isSubPathMatch =
        locationPathName.startsWith(callToActionUrl) && !children?.length;

      const hasChildPermission = children?.length
        ? hasPermission(children)
        : false;

      return isDirectMatch || isSubPathMatch || hasChildPermission;
    });
  };

  return (
    hasPermission(userAccessibleSections) ||
    ignorePages.includes(path) ||
    path?.includes("/dashboard")
  );
};

export const DashboardLayoutRoute: React.FC<DashboardLayoutRouteProps> = ({
  component,
  path,
  exact,
}) => {
  const location = useLocation();
  const isPendingRole = useUserRole() === "pending";
  const isLoggedIn = useUserLoggedInStatus();
  const { sync, loading, error, userAccessibleSections } =
    useUserAccessibleSections();

  const {
    sync: syncDashboard,
    loading: loadingDashboard,
    error: errorDashboard,
    markAsHomepage,
    configs: { homepage },
  } = useUserDashboard();

  const hasETAGChanged = useCheckFEVersionHasChanged();

  usePageChange(async () => {
    if (hasETAGChanged) {
      window.location.reload();
      return;
    }
    const version = await fetchVersionFromIndexHtml();
    if (
      version !== CURRENT_VERSION &&
      isVersionValid(CURRENT_VERSION) &&
      isVersionValid(version)
    ) {
      // eslint-disable-next-line no-alert
      // alert("usePageChange2");
      window.location.reload();
    }
  });
  const { pathname } = location;

  if (!isLoggedIn) {
    return redirectToLogin(pathname);
  }

  if (isPendingRole) {
    return (
      <PageLayout>
        <COREError
          title={
            "No permission to view this page. This account is still pending."
          }
        />
      </PageLayout>
    );
  }

  const permission = checkUserAccess(userAccessibleSections, path);

  switch (true) {
    case (loading && !sync && !error) ||
      (loadingDashboard && !syncDashboard && !errorDashboard):
      return <CORELoading size={"lg"} />;
    case !!error || !!errorDashboard:
      return (
        <COREError
          title={`Error loading feature list. ${
            error &&
            typeof error === "object" &&
            error.hasOwnProperty("message")
              ? error.message
              : ""
          }`}
        />
      );
    case !sync || !syncDashboard:
      return (
        <PageLayout>
          <Empty />
        </PageLayout>
      );
    case permission:
      return <Route path={path} component={component} exact={exact} />;
    default: {
      return (
        <PageLayout>
          <COREError
            title={noPermissionMessage}
            path={pathname === homepage ? "/home" : "/"}
            onClick={() => {
              if (pathname === homepage) {
                markAsHomepage("/home");
              }
            }}
          />
        </PageLayout>
      );
    }
  }
};
