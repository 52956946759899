import React, { useState } from "react";
import "./UserDrawer.less";
import { useUser } from "../state/user";
import { generateTestId, TestIDWrapper } from "../testids/testids";
import { COREBody } from "../../COREDesignSystem/Typography/COREBody";
import { COREButton } from "../../COREDesignSystem/Action/COREButton";
import { COREUserDrawer } from "../../COREDesignSystem/Overlay/COREUserDrawer";
import { COREIcon } from "../../COREDesignSystem/Content/COREIcon";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import { Badge } from "antd";
import { purple100 } from "../../COREDesignSystem/Content/COREColour";
import { useTotalTodo } from "../customHoooks/useTasks";
import { useFeatureFlags } from "../customHoooks/useFeatureFlags";

export const UserDrawer = () => {
  const user = useUser();
  const { totalTodo } = useTotalTodo();
  const { features } = useFeatureFlags();
  const hasTodoPermission = features?.includes("todo-list");
  const name = user?.name ?? "undefined name";
  const [collapsedUserDrawer, setCollapsedUserDrawer] = useState(true);
  return (
    <>
      <div
        className={classNames("user-panel-section")}
        onClick={() => {
          setCollapsedUserDrawer(false);
        }}
      >
        <COREBody
          marginBottom={false}
          className={"user-full-name-text"}
          testID={generateTestId("page", "user-name")}
        >
          {name}
        </COREBody>

        <TestIDWrapper testID={generateTestId("page", "userpanel-dropdown")}>
          <Badge
            count={hasTodoPermission ? totalTodo : 0}
            color={purple100}
            showZero={false}
          >
            <COREButton
              icon={
                <COREIcon icon={icon({ name: "user", style: "regular" })} />
              }
              type={"primary"}
            />
          </Badge>
        </TestIDWrapper>
      </div>
      <COREUserDrawer
        name={name}
        collapsed={collapsedUserDrawer}
        setCollapsed={setCollapsedUserDrawer}
        testId={generateTestId("page", "user-drawer")}
      />
    </>
  );
};
