import React, { lazy, Suspense } from "react";
import { ConfigProvider, message } from "antd";
import {
  BrowserRouter as Router,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import "./App.less";

/** Layouts **/
import { LoginLayoutRoute } from "./modules/pages/layouts/LoginLayout";
import { CORELoading } from "./COREDesignSystem/Feedback/CORELoading";
import { ScrollToTopWithRouter as ScrollToTop } from "./modules/pages/ScrollToTop";
import { useNetworkState, usePrevious } from "react-use";
import { DashboardLayoutRoute } from "./modules/pages/layouts/DashboardLayoutRoute";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { appQueryClient } from "./shared/state/appQueryClient";
import { datum } from "./shared/datum";
import { useReactQuerySubscription } from "./shared/state/websocket";
import { PageLayout } from "./modules/pages/layouts/PageLayout";
import { useUserLoggedInStatus } from "./shared/state/user";
import { Helmet } from "react-helmet";
import { isDevEnv } from "./shared/state/rest";

type RouteList = {
  path: Exclude<RouteProps["path"], undefined | readonly string[]>;
  component:
    | React.LazyExoticComponent<React.FunctionComponent>
    | React.LazyExoticComponent<
        (props: { datum?: typeof datum }) => JSX.Element
      >;
};

const HomePage = lazy(() => import("./modules/pages/Home"));
const DefaultHomePage = lazy(
  () => import("./modules/globalHomePage/GlobalHomepagePageWrapper")
);
const LoginPage = lazy(() => import("./modules/pages/login/LoginPage"));
const LogoutPage = lazy(() => import("./modules/pages/logout/Logout"));
const NoMatchPage = lazy(
  () => import("./modules/pages/noMatchPage/NoMatchPage")
);
const SetPasswordPage = lazy(
  () => import("./modules/pages/resetPassword/SetPasswordPage")
);
const ActivateAccountPage = lazy(
  () => import("./modules/pages/activateAccount/ActivateAccountPage")
);
const ForgotPasswordPage = lazy(
  () => import("./modules/pages/forgotPassword/ForgotPasswordPage")
);
const VerifyTwoFactor = lazy(
  () => import("./modules/pages/verifyTwoFactor/VerifyTwoFactor")
);
const MFASetup = lazy(() => import("./modules/admin/userManagement/MFASetup"));
const BlackLiveMarketScreen = lazy(
  () => import("./modules/marketsAdmin/marketPrices/Electricity")
);
const CarbonLiveMarket = lazy(
  () => import("./modules/marketsAdmin/marketPrices/Carbon")
);
const EnviroLiveMarketScreen = lazy(
  () => import("./modules/marketsAdmin/marketPrices/Environmental")
);
const MarketsSnapshotPage = lazy(
  () => import("./modules/markets/MarketsSnapshotPageWrapper")
);
const HoldingsPage = lazy(
  () => import("./modules/wholesaleInventories/HoldingsPageWrapper")
);
const REHUBLiveMarketScreen = lazy(
  () => import("./modules/marketsAdmin/marketPrices/ElectricityShape")
);
const DashboardPage = lazy(
  () => import("./modules/dashboards/DashboardPageWrapper")
);
const TradeTrackerPage = lazy(
  () => import("./modules/marketsAdmin/TradeTrackerPageWrapper")
);
const TradeTimelinePage = lazy(
  () => import("./modules/markets/TradeTimelinePageWrapper")
);
const UserAnalyticPage = lazy(
  () => import("./modules/admin/AnalyticPageWrapper")
);
const UserManagementPage = lazy(
  () => import("./modules/admin/UserManagementPageWrapper")
);
const APITokensPage = lazy(() => import("./modules/admin/ApiTokenPageWrapper"));
const AddEditUserPageWrapper = lazy(
  () =>
    import("./modules/admin/userManagement/addEditUsers/AddEditUserPageWrapper")
);
const TodoPageWrapper = lazy(
  () => import("./modules/toDoList/TodoPageWrapper")
);

const MyDetailsPageWrapper = lazy(
  () =>
    import(
      "./modules/admin/userManagement/addEditUsers/myDetails/MyDetailsPageWrapper"
    )
);
const TraderViewPage = lazy(
  () => import("./modules/tools/TraderViewPageWrapper")
);

const EnvironDailyCurve = lazy(
  () => import("./modules/markets/dailyCurves/EnvironDailyCurvePageWrapper")
);

const ElectricityDailyCurve = lazy(
  () => import("./modules/markets/dailyCurves/ElectricityDailyCurvePageWrapper")
);

const RehubDailyCurvePage = lazy(
  () =>
    import(
      "./modules/markets/dailyCurves/ElectricityShapeRehubDailyCurvePageWrapper"
    )
);

const CarbonDailyCurvePage = lazy(
  () => import("./modules/markets/dailyCurves/CarbonDailyCurvePageWrapper")
);

const ChartBuilderPage = lazy(
  () => import("./modules/chartBuilder/ChartBuilderPageWrapper")
);
const CompanyPage = lazy(
  () => import("./modules/admin/CompanyManagementPageWrapper")
);
const AddCompanyPage = lazy(
  () =>
    import(
      "./modules/admin/companyManagement/addEditCompany/AddCompanyPageWrapper"
    )
);
const EditCompanyPage = lazy(
  () =>
    import(
      "./modules/admin/companyManagement/addEditCompany/EditCompanyPageWrapper"
    )
);

const SubscriptionPackagesPage = lazy(
  () => import("./modules/admin/SubscriptionPackagesPageWrapper")
);

const AddEditSubscriptionPackagesPage = lazy(
  () =>
    import("./modules/admin/subscriptionPackage/AddEditSubscriptionPackages")
);

const ProjectMarketplace = lazy(
  () => import("./modules/projectMarketplace/ProjectMarketplacePageWrapper")
);
const DBActivity = lazy(() => import("./modules/superAdmin/DBActivity"));
const ProjectDetail = lazy(
  () =>
    import(
      "./modules/projectMarketplace/projectMarketplaceDetail/ProjectMarketPlaceDetail"
    )
);
const ManageProject = lazy(
  () => import("./modules/marketsAdmin/ManageProjectContainer")
);
const EditCertificateProject = lazy(
  () =>
    import(
      "./modules/marketsAdmin/certificateProjects/manageProjects/addEditProjects/EditProject"
    )
);
const AddCertificateProject = lazy(
  () =>
    import(
      "./modules/marketsAdmin/certificateProjects/manageProjects/addEditProjects/AddProject"
    )
);

const ProjectEligibilities = lazy(
  () => import("./modules/marketsAdmin/EligibilitiesPageContainer")
);

const SectionPage = lazy(() => import("./modules/section/Index"));

const EmissionsCarbonBudgets = lazy(
  () => import("./modules/emissions/CarbonBudgets")
);

const EmissionsAddInitiatives = lazy(
  () => import("./modules/emissions/initiativeComponents/AddInitiatives")
);

const EmissionsViewInitiatives = lazy(
  () => import("./modules/emissions/ViewInitiatives")
);

const EmissionsSpecificPortfolioInitiatives = lazy(
  () =>
    import(
      "./modules/emissions/initiativeComponents/SpecificPortfolioInitiatives"
    )
);
const EmissionsScenarios = lazy(
  () => import("./modules/emissions/ScenariosPage")
);
const EmissionsScenario = lazy(
  () => import("./modules/emissions/scenarioComponents/ScenarioPage")
);

const EmissionsViewOffsets = lazy(
  () => import("./modules/emissions/ViewOffsets")
);

const EmissionsAddOffsets = lazy(
  () => import("./modules/emissions/offsetComponents/AddOffsets")
);

const EmissionsSpecificPortfolioOffsets = lazy(
  () => import("./modules/emissions/offsetComponents/SpecificPortfolioOffsets")
);

const EmissionsPortfolios = lazy(
  () => import("./modules/emissions/Portfolios")
);

const EmissionsAddPortfolios = lazy(
  () =>
    import(
      "./modules/emissions/portfolioComponents/createAndEdit/CreateEditPortfolio"
    )
);

const EmissionsEditPortfoliosPreferences = lazy(
  () =>
    import(
      "./modules/emissions/portfolioComponents/portfolioPreferences/EditPortfoliosPreferences"
    )
);

const EmissionsReporting = lazy(() => import("./modules/emissions/Reporting"));

const EmissionsGettingStarted = lazy(
  () => import("./modules/emissions/GettingStartedEmissions")
);

const EmissionsOverview = lazy(() => import("./modules/emissions/Overview"));

const EmissionPortfolioDetail = lazy(
  () =>
    import(
      "./modules/emissions/portfolioComponents/portfolioDetail/PortfolioDetail"
    )
);

const MarketsAdminCounterParties = lazy(
  () => import("./modules/marketsAdmin/CounterpartyPageWrapper")
);

const MarketsAdminAwaybrokers = lazy(
  () => import("./modules/marketsAdmin/AwaybrokerPageWrapper")
);
const AddCounterParties = lazy(
  () =>
    import(
      "./modules/marketsAdmin/tradeParties/counterParties/addEditCounterParties/profile/AddProfileContainer"
    )
);
const EditCounterParties = lazy(
  () =>
    import(
      "./modules/marketsAdmin/tradeParties/counterParties/addEditCounterParties/profile/EditProfileContainer"
    )
);
const Reports = lazy(() => import("./modules/insights/ReportsPageWrapper"));

const ReportsDetail = lazy(
  () => import("./modules/insights/reports/ReportsDetail")
);

const BespokeReports = lazy(
  () => import("./modules/insights/BespokeReportsPageWrapper")
);

const BespokeReportsDetail = lazy(
  () => import("./modules/insights/bespokeReports/BespokeReportsDetail")
);
const CMSContentManagementPublicationsPage = lazy(
  () =>
    import(
      "./modules/admin/contentManagement/publications/PublicationsPageWrapper"
    )
);
const CMSContentManagementPublicationsAddPage = lazy(
  () => import("./modules/admin/contentManagement/publications/AddPublications")
);
const CMSContentManagementPublicationsEditPage = lazy(
  () =>
    import("./modules/admin/contentManagement/publications/EditPublications")
);
const CMSContentManagementNewsAndAlertPage = lazy(
  () =>
    import(
      "./modules/admin/contentManagement/newsAndAlerts/NewsAndAlertsPageWrapper"
    )
);
const CMSContentManagementAddNewsAndAlertPage = lazy(
  () =>
    import(
      "./modules/admin/contentManagement/newsAndAlerts/AddEditNewsAndAlertsPageWrapper"
    )
);
const InsightNewsAndAlertsPage = lazy(
  () => import("./modules/insights/InsightNewsAndAlertsPageWrapper")
);
const ArticlesAndUpdatesPage = lazy(
  () => import("./modules/insights/ArticlesAndUpdatesPageWrapper")
);
const MarketCarbonEnvironmentalPowerPageWrapper = lazy(
  () => import("./modules/markets/MarketCarbonEnvironmentalPowerPageWrapper")
);

const chartBuilderRouteList: RouteList[] = [
  {
    path: "/chart-builder",
    component: ChartBuilderPage,
  },
];

const toolsRouteList: RouteList[] = [
  {
    path: "/tools/trader-view",
    component: TraderViewPage,
  },
  {
    path: "/tools",
    component: SectionPage,
  },
];

const marketsAdminRouteList: RouteList[] = [
  {
    path: "/markets-admin/market-prices/electricity-shapes",
    component: REHUBLiveMarketScreen,
  },
  {
    path: "/markets-admin/market-prices/electricity",
    component: BlackLiveMarketScreen,
  },
  {
    path: "/markets-admin/market-prices/carbon",
    component: CarbonLiveMarket,
  },
  {
    path: "/markets-admin/market-prices/environmental",
    component: EnviroLiveMarketScreen,
  },
  {
    path: "/markets-admin/market-prices",
    component: SectionPage,
  },
  {
    path: "/market-prices",
    component: SectionPage,
  },
  {
    path: "/markets-admin/certificate-projects/projects/add",
    component: AddCertificateProject,
  },
  {
    path: "/markets-admin/certificate-projects/projects/:id/project-details",
    component: EditCertificateProject,
  },
  {
    path: "/markets-admin/certificate-projects/projects",
    component: ManageProject,
  },
  {
    path: "/markets-admin/certificate-projects/eligibilities",
    component: ProjectEligibilities,
  },
  {
    path: "/markets-admin/certificate-projects",
    component: SectionPage,
  },
  {
    path: "/markets-admin/trade-parties/away-brokers",
    component: MarketsAdminAwaybrokers,
  },
  {
    path: "/markets-admin/trade-parties/counterparties/:id/profile",
    component: EditCounterParties,
  },
  {
    path: "/markets-admin/trade-parties/counterparties/add",
    component: AddCounterParties,
  },
  {
    path: "/markets-admin/trade-parties/counterparties",
    component: MarketsAdminCounterParties,
  },
  {
    path: "/markets-admin/trade-parties",
    component: SectionPage,
  },
  {
    path: "/markets-admin/trade-tracker",
    component: TradeTrackerPage,
  },
  {
    path: "/markets-admin",
    component: SectionPage,
  },
];

const wholesaleInventoriesRouteList: RouteList[] = [
  {
    path: "/wholesale-inventory/registry-holdings",
    component: HoldingsPage,
  },
  {
    path: "/wholesale-inventory",
    component: SectionPage,
  },
];

const marketsRouteList: RouteList[] = [
  {
    path: "/markets/daily-curves/electricity-shapes/:date?",
    component: RehubDailyCurvePage,
  },
  {
    path: "/markets/daily-curves/electricity/:date?",
    component: ElectricityDailyCurve,
  },
  {
    path: "/markets/daily-curves/environmental/:date?",
    component: EnvironDailyCurve,
  },
  {
    path: "/markets/daily-curves/carbon/:date?",
    component: CarbonDailyCurvePage,
  },
  {
    path: "/markets/daily-curves",
    component: SectionPage,
  },
  {
    path: "/markets/carbon/australia",
    component: SectionPage,
  },
  {
    path: "/markets/:marketType/:region/:menuName",
    component: MarketCarbonEnvironmentalPowerPageWrapper,
  },
  {
    path: "/markets/carbon/international",
    component: SectionPage,
  },
  {
    path: "/markets/carbon/new-zealand",
    component: SectionPage,
  },
  {
    path: "/markets/carbon",
    component: SectionPage,
  },
  {
    path: "/markets/environmental/australia",
    component: SectionPage,
  },
  {
    path: "/markets/environmental",
    component: SectionPage,
  },
  {
    path: "/markets/power/australia",
    component: SectionPage,
  },
  {
    path: "/markets/power",
    component: SectionPage,
  },
  {
    path: "/markets/markets-snapshot",
    component: MarketsSnapshotPage,
  },
  {
    path: "/markets/trade-timeline",
    component: TradeTimelinePage,
  },
  {
    path: "/markets",
    component: SectionPage,
  },
];

const projectMarketplaceRouteList: RouteList[] = [
  {
    path: "/project-marketplace/details/:id",
    component: ProjectDetail,
  },
  {
    path: "/project-marketplace",
    component: ProjectMarketplace,
  },
];

const adminRouteList: RouteList[] = [
  {
    path: "/admin/user-management/add",
    component: AddEditUserPageWrapper,
  },
  {
    path: "/admin/api-tokens",
    component: APITokensPage,
  },
  {
    path: "/admin/user-management/:id/profile",
    component: AddEditUserPageWrapper,
  },
  {
    path: "/admin/user-management",
    component: UserManagementPage,
  },
  {
    path: `/admin/analytics/frontend`,
    component: UserAnalyticPage,
  },
  {
    path: "/admin/analytics",
    component: SectionPage,
  },
  {
    path: "/admin/subscription-packages/add",
    component: AddEditSubscriptionPackagesPage,
  },
  {
    path: "/admin/subscription-packages/:id",
    component: AddEditSubscriptionPackagesPage,
  },
  {
    path: "/admin/content-management/publications/:id/edit",
    component: CMSContentManagementPublicationsEditPage,
  },
  {
    path: "/admin/content-management/publications/add",
    component: CMSContentManagementPublicationsAddPage,
  },
  {
    path: "/admin/content-management/publications",
    component: CMSContentManagementPublicationsPage,
  },
  {
    path: "/admin/content-management",
    component: SectionPage,
  },
  {
    path: "/admin/subscription-packages",
    component: SubscriptionPackagesPage,
  },
  {
    path: "/admin/company-management/:id/overview",
    component: EditCompanyPage,
  },
  {
    path: "/admin/company-management/add",
    component: AddCompanyPage,
  },
  {
    path: "/admin/company-management",
    component: CompanyPage,
  },
  {
    path: "/admin/content-management/news-and-alerts/add",
    component: CMSContentManagementAddNewsAndAlertPage,
  },
  {
    path: "/admin/content-management/news-and-alerts/:id/edit",
    component: CMSContentManagementAddNewsAndAlertPage,
  },
  {
    path: "/admin/content-management/news-and-alerts",
    component: CMSContentManagementNewsAndAlertPage,
  },
  {
    path: "/admin/db-activity",
    component: DBActivity,
  },
  {
    path: "/admin",
    component: SectionPage,
  },
];

const usersRouteList: RouteList[] = [
  {
    path: "/my-details",
    component: MyDetailsPageWrapper,
  },
  {
    path: "/todo",
    component: TodoPageWrapper,
  },
];

const emissionsManagerRouteList: RouteList[] = [
  {
    path: `/emissions-manager/getting-started`,
    component: EmissionsGettingStarted,
  },
  {
    path: `/emissions-manager/carbon-budgets`,
    component: EmissionsCarbonBudgets,
  },
  {
    path: `/emissions-manager/initiatives/add`,
    component: EmissionsAddInitiatives,
  },
  {
    path: `/emissions-manager/initiatives/:id/view`,
    component: EmissionsViewInitiatives,
  },
  {
    path: `/emissions-manager/initiatives/:id`,
    component: EmissionsSpecificPortfolioInitiatives,
  },
  {
    path: `/emissions-manager/initiatives`,
    component: EmissionsSpecificPortfolioInitiatives,
  },
  {
    path: `/emissions-manager/scenarios/:id`,
    component: EmissionsScenario,
  },
  {
    path: `/emissions-manager/scenarios`,
    component: EmissionsScenarios,
  },

  {
    path: `/emissions-manager/offsets/add`,
    component: EmissionsAddOffsets,
  },
  {
    path: `/emissions-manager/offsets/:id/view`,
    component: EmissionsViewOffsets,
  },
  {
    path: `/emissions-manager/offsets/:id`,
    component: EmissionsSpecificPortfolioOffsets,
  },
  {
    path: `/emissions-manager/offsets`,
    component: EmissionsSpecificPortfolioOffsets,
  },
  {
    path: `/emissions-manager/portfolios/create`,
    component: EmissionsAddPortfolios,
  },
  {
    path: `/emissions-manager/portfolios/:id/details/:scopeId/:page`,
    component: EmissionPortfolioDetail,
  },
  {
    path: `/emissions-manager/portfolios/:id/preferences/:page`,
    component: EmissionsEditPortfoliosPreferences,
  },
  {
    path: `/emissions-manager/portfolios/:id/edit`,
    component: EmissionsAddPortfolios,
  },
  {
    path: `/emissions-manager/portfolios`,
    component: EmissionsPortfolios,
  },
  {
    path: `/emissions-manager/reporting`,
    component: EmissionsReporting,
  },
  {
    path: `/emissions-manager/overview/:id`,
    component: EmissionsOverview,
  },
  {
    path: `/emissions-manager/overview`,
    component: EmissionsOverview,
  },
  {
    path: `/emissions-manager`,
    component: SectionPage,
  },
];

const otherRouteList: RouteList[] = [
  {
    path: "/dashboard/:name",
    component: DashboardPage,
  },
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/dashboards",
    component: SectionPage,
  },
  {
    path: "/home",
    component: DefaultHomePage,
  },
];

const insightsRouteList: RouteList[] = [
  {
    path: "/insights/reports/all-reports/:id/view",
    component: ReportsDetail,
  },
  {
    path: "/insights/reports/carbon-enviro/:id/view",
    component: ReportsDetail,
  },
  {
    path: "/insights/reports/market-forecasts/:id/view",
    component: ReportsDetail,
  },
  {
    path: "/insights/reports/power-reports/:id/view",
    component: ReportsDetail,
  },
  {
    path: "/insights/reports/research-reports/:id/view",
    component: ReportsDetail,
  },
  {
    path: "/insights/reports/:menu",
    component: Reports,
  },
  {
    path: "/insights/reports",
    component: SectionPage,
  },
  {
    path: "/insights/articles-and-updates",
    component: ArticlesAndUpdatesPage,
  },
  {
    path: "/insights/bespoke-reports/:id",
    component: BespokeReportsDetail,
  },
  {
    path: "/insights/bespoke-reports",
    component: BespokeReports,
  },
  {
    path: "/insights",
    component: SectionPage,
  },
];

const loginRouteList: RouteList[] = [
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/set-password/:token",
    component: SetPasswordPage,
  },
  {
    path: "/account-activation",
    component: ActivateAccountPage,
  },
  {
    path: "/forgot-password",
    component: ForgotPasswordPage,
  },
  {
    path: "/verify-two-factor/:userToken",
    component: VerifyTwoFactor,
  },
];

const insightRouteList: RouteList[] = [
  {
    path: "/insights/news-and-alerts",
    component: InsightNewsAndAlertsPage,
  },
  {
    path: "/insights",
    component: SectionPage,
  },
];

const allRouteListExceptLogin: RouteList[] = [
  ...chartBuilderRouteList,
  ...toolsRouteList,
  ...marketsAdminRouteList,
  ...marketsRouteList,
  ...adminRouteList,
  ...usersRouteList,
  ...insightsRouteList,
  ...projectMarketplaceRouteList,
  ...emissionsManagerRouteList,
  ...wholesaleInventoriesRouteList,
  ...insightRouteList,
  ...otherRouteList,
];

export const allRouteList: RouteList[] = [
  ...allRouteListExceptLogin,
  ...loginRouteList,
];

function Websockets() {
  useReactQuerySubscription();
  return null;
}

export const App = () => {
  const { online } = useNetworkState();
  const prevOnline = usePrevious(online);
  const isLoggedIn = useUserLoggedInStatus();
  const onlineStatusChanged = prevOnline !== online && prevOnline !== undefined;

  if (onlineStatusChanged && online)
    message.info("Internet connection restored.");
  else if (onlineStatusChanged && online === false)
    message.warning("Lost internet connection.");

  const faviconPrefix = isDevEnv() ? "dev_" : "";
  return (
    <QueryClientProvider client={appQueryClient}>
      <ConfigProvider>
        <Websockets />
        <Router>
          <Suspense
            fallback={
              isLoggedIn ? (
                <PageLayout>
                  <CORELoading message={"Loading Page"} />
                </PageLayout>
              ) : (
                <CORELoading message={"Loading Page"} />
              )
            }
          >
            <Helmet>
              <link
                rel="icon"
                type="image/x-icon"
                href={`/${faviconPrefix}favicon.ico`}
              />
              <link rel="shortcut icon" href={`/${faviconPrefix}favicon.ico`} />
              <link rel="manifest" href={`/${faviconPrefix}manifest.json`} />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={`/${faviconPrefix}apple-touch-icon.png`}
              />
            </Helmet>
            <ScrollToTop />
            <Switch>
              <Route path={"/mfa-setup"} render={() => <MFASetup />} />
              {allRouteListExceptLogin.map(({ component, path }) => (
                <DashboardLayoutRoute
                  key={path}
                  path={path}
                  exact={true}
                  component={component}
                />
              ))}

              {loginRouteList.map(({ path, component }, index) => (
                <LoginLayoutRoute
                  key={index}
                  component={component}
                  path={path}
                />
              ))}
              <Route exact path={"/logout"} component={LogoutPage} />
              <Route component={NoMatchPage} />
            </Switch>
          </Suspense>
        </Router>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
